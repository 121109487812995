"use strict";

class ScrollListener {

    constructor() {

        this.el = document.querySelector('.cart.headhesive');

        window.addEventListener('scroll', _ => {
            if( window.scrollY > 30) {
                this.el.classList.add('headhesive--stick');
                this.el.classList.remove('headhesive--unstick');
            } else {
                this.el.classList.add('headhesive--unstick');
                this.el.classList.remove('headhesive--stick');
            }
        });
    }

}
 
new ScrollListener();
